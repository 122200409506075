import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "../../../components/UI/Slider";
import { BRANDS, COUNTRY } from "../../../constants/app";
import { API_REQUEST_TYPE, URLS } from "../../../constants/url";
import { RootState } from "../../../store";
import {
  getSlidesWatcher,
  setFooterType,
} from "../../../store/application/action";
import {
  getCardCategory,
  getGiftCardPromoWatcher,
  setGiftShopAction,
  setGiftShopTab,
  setGiftShopTabId,
} from "../../../store/giftshop/action";
import "./giftshop.scss";
import { GIFT_CARD_ACTION, GIFT_CARD_CATEGORY_TYPE } from "../../../models/giftcard";
import GiftShopFooter from "./Footer";
import HelmetTitle from "../../../components/Helmet";
import { ICardCategory } from "../../../store/giftshop/type";
import Cards from "./Cards";

interface IGiftShopProps { }

const GiftShop: FC<IGiftShopProps> = () => {

  const {
    slides,
    countryId,
    currentTab,
    brandId,
    currentCinema,
    currentAction,
    cardCategory,
    currentTabId,
  } = useSelector((state: RootState) => ({
    slides: state.applicationReducer.slides,
    countryId: state.applicationReducer.countryId,
    currentTab: state.giftCardReducer.currentTab,
    currentAction: state.giftCardReducer.currentAction,
    brandId: state.applicationReducer.brandId,
    currentCinema: state.applicationReducer.currentCinema,
    cardCategory: state.giftCardReducer.cardCategory,
    currentTabId: state.giftCardReducer.currentTabId,
  }));

  const dispatch = useDispatch();

  const getGiftCardPromo = async () => {
    const payload = {
      cinemaId: currentCinema?.slug,
    };
    await dispatch(getGiftCardPromoWatcher(payload));
  };

  useEffect(() => {
    if (countryId) {
      dispatch(
        getCardCategory({
          countryId,
        })
      );
      dispatch(setGiftShopAction(GIFT_CARD_ACTION.LIST));
      dispatch(
        getSlidesWatcher({
          countryId,
          pageName: API_REQUEST_TYPE.GIFT_SHOP,
          location: currentCinema?.slug,
        })
      );
      dispatch(setFooterType(10));
    }
  }, [countryId]);

  useEffect(() => {
    if (currentCinema && currentTabId) {
      getGiftCardPromo();
    }
  }, [currentCinema, currentTabId]);


  useEffect(() => {
    if (cardCategory.length > 0) {
      dispatch(
        setGiftShopTab(GIFT_CARD_CATEGORY_TYPE[cardCategory[0].category_type])
      );
      dispatch(setGiftShopTabId(cardCategory[0].id));
      dispatch(setGiftShopAction(GIFT_CARD_ACTION.LIST));
    }
  }, [cardCategory]);

  const changeTab = (category: ICardCategory) => {
    dispatch(setGiftShopTab(GIFT_CARD_CATEGORY_TYPE[category.category_type]));
    dispatch(setGiftShopTabId(category.id));
    dispatch(setGiftShopAction(GIFT_CARD_ACTION.LIST));
  };

  return (
    <section className="gift-us">
      <HelmetTitle title="Gift cards" description="Gift cards" />
      {/* Header */}
      {currentAction === GIFT_CARD_ACTION.LIST ? (
        <div>
          <div className={brandId === BRANDS.US ? "slider-filter" : "col"}>
            <Slider
              className={brandId === BRANDS.US ? "banner-slider-us" : ""}
              data={slides}
            />
          </div>
        </div>
      ) : null}
      {/* Body */}
      <div>
        <div className="banner-top">
          <div className="banner-top-inner">
            <h2>Choose an experience</h2>
            <p>
              <span>{countryId === COUNTRY.STA ? "A State Cinemas " : (countryId === COUNTRY.ANG ? "An Angelika Cinemas " : "A Reading Cinemas ")}</span>
              Gift Card is the perfect gift for anyone and any occasion.
              Gift cards can be used to book tickets and purchase snacks online
              or in person.
            </p>
            <p>
              For that last minute gift, e-Gift Cards are delivered to your
              inbox instantly!
            </p>
          </div>
        </div>

        <div className="gift-shop-tab">
          <div className="stickytabmenu">
            <div className="container">
              <ul className="nav" role="tablist">
                {cardCategory &&
                  cardCategory.map((category: ICardCategory) => (
                    <li
                      className="nav-item"
                      onClick={() => changeTab(category)}
                    >
                      <a
                        className={`nav-link ${currentTabId === category.id ? "active" : ""
                          }`}
                      >
                        {category.category_name}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
        <Cards />
      </div>
      <GiftShopFooter page="giftshop" />
    </section>
  );
};

export default GiftShop;